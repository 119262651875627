import React from 'react'
import './ServicesClient.css'
import {Container,Row,Col} from 'react-bootstrap';

function ServicesClient(){
    return (
        <>
        <section id="servicesclient_main-section">
            <Container className='servicesclient_main-container'> 
                <Row xl={4} lg={4} sm={2} md={2} xs={2}>
                <Col className="servicesclient_textcol" sm={12} xs={12} md={12} lg={12} xl={12}>
                <h1>Trusted by Global Enterprises</h1></Col>
                 <Col className="servicesclient_icon-col"><span className='servicesclient_icon icon-col'><img src="nirmata.png" alt="icon" className='icon nirmata'/></span></Col>
                 <Col className="servicesclient_icon-col"><span className='servicesclient_icon'><img src="armorcode.png" alt="icon" className='icon armorcode'/></span></Col>
                 <Col className="servicesclient_icon-col"><span className='servicesclient_icon'><img src="anzenna.svg" alt="icon" className='icon anzenna'/></span></Col>
                 <Col className="servicesclient_icon-col"><span className='servicesclient_icon'><img src="curriculumassociates.png" height={80} alt="icon" className='icon curriculum'/></span></Col>
                </Row>
        </Container>
        </section>
        </>
    )
}

export default ServicesClient;