import React from "react";
import "./ServicesPage3.css";
import { Container, Row, Col } from "react-bootstrap";

function ServicesPage3() {
  return (
    <>
    <section id="servicespage3_main-section">
      <Container fluid className="servicespage3_main-container">
        <section id="servicespage3_second-container">
        <Container className="servicespage3_second-container">
          <h1>What makes us a top </h1><h1>software development company?</h1>
          <p>
            Successful apps are not created in one day. It needs a strong team,
            the right approach and process, utmost commitment, core experience,
            and our extended support. We&#8217;ve got everything.
          </p>
        </Container>
        </section>
        <section id="servicespage3_third-section">
        <Container className="servicespage3_third-container">
          <img src="servicespage3image.png" alt="servicespage3image" className="servicespage3image" />
          <Container fluid className="servicespage3_row-container">
            <Row>
              <Col xs={12} className="servicespage3-col">
                <img src="mobile-dev.png" height={43} alt="servicespage3image"/>
                  <h4>Our Belief</h4>
                  <p>
                    At Hurain Infotech, we believe that technology should be
                    accessible and easy to use for everyone. That&#8217;s why we
                    strive to create userfriendly software that are not only
                    functional, but also visually appealing. We understand that
                    every business is different, and that&#8217;s why we take this
                    tirne to get to know our clients, their goals, and their
                    challenges.
                  </p>
              </Col>
              <Col xs={12} className="servicespage3-col">
                <img src="software-dev.png" alt="servicespage3image" height={43} />
                  <h4>Our Mission</h4>
                  <p>
                    We strongly follow a customer-centric approach. To deliver
                    unbeatable mobile and web apps, we understand your end
                    customers to the core. Anything that&#8217;s just the BEST for
                    your end customers is what we focus on. The apps we develop
                    must help you scale up your business and gain competitive
                    advantage.
                  </p>
              </Col>
            </Row>
          </Container>
        </Container>
        </section>
      </Container>
      </section>
    </>
  );
}

export default ServicesPage3;
