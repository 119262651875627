import './Header.css'
import {Container, Navbar, Nav} from 'react-bootstrap'
import {useState,useEffect} from 'react';
function Header() {
    const [scrolled,setScrolled]=useState(false)
    useEffect(()=>{
      window.addEventListener('scroll',handleScroll)
         function handleScroll(){
          if(window.scrollY>=200){
          setScrolled(true)
          }
         else{
          setScrolled(false)
         }
         }
      return ()=>{
        window.removeEventListener('scroll',handleScroll)
      }
    },[])
  return (
    <>
    <section id="main_header-section">
    <Navbar expand="lg" className={`navbar_header ${scrolled ? "scroll" : ""}`}>   
        <Container fluid="md" className='header_container'>
      <Navbar.Brand className='nav_brand'>
      <Nav.Link href="#home_main-section"><img src="huraininfotechlogo.svg" alt="logo" className='header_logo'/></Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle/>
      <Navbar.Collapse className="navbar_collapse">
      <Nav className="nav_header">
        <Nav.Link href="#home_main-section">Home</Nav.Link>
        <Nav.Link href="#about_main-section">About Us</Nav.Link>
        <Nav.Link href="#services_main-section">Services</Nav.Link>
        <Nav.Link href="#contactus_main-section">Contact Us</Nav.Link>
        </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>
      </section>
    </>
  );
}

export default Header;
