import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <>
    <Header/>
    <Home/>
    <About/>
    <Services/>
    <ContactUs/>
    </>
  );
}

export default App;
