import React from 'react';
import "./Footer.css";
import {Container, Button} from 'react-bootstrap'


function Footer(){
    return (
        <>
         <Container fluid className="footer_main-container">
            {/* <Container fluid className="footer_second-container">
            <div className='footer_third-container'>
                <img src="huraininfotechlogo.svg" height={60} alt="logo"/>
                <p>Web Design and Development Services tailored to your business vision. Captivate your visitors and let your voice be heard.</p>
                <Button variant="link" href='https://www.linkedin.com/company/hurain-infotech-llp' target='_blank'><img src="in-icon.png" alt="in"/></Button>
             </div>
            <Container fluid className="footer_fourth-container">
            <div>
                <h4>Services</h4>
            <ul className="second-list">
                <li>Custom Software Development</li>
                <li>Mobile App Development</li>
                <li>Web Development</li>
                <li>Enterprise Mobility</li>
                <li>Hire Developers</li>
            </ul>
            </div>
            <div className='third-list'>
            <ul>
                 
                <li>Software Testing Services</li>
                <li>Support & Maintenance</li>
                <li>Consulting Services</li>
                <li>UI/UX Design</li>
            </ul>
            </div>
            </Container>
            </Container> */}
            <div className="footer_fifth-container">
                <div>
                    Follow us on <Button variant="link" href='https://www.linkedin.com/company/hurain-infotech-llp' target='_blank'><img src="in-icon.png" alt="in"/></Button>
                    
                </div>
                <div>
                    <span>&#169;</span>
                    <span>2024 Hurain Infotech. All rights reserved.</span>
                </div>
            </div>
            </Container>

        </>
    )
}

export default Footer