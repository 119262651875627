import React from 'react';
import './ContactUs.css';
import {Container, ListGroup} from 'react-bootstrap';
import Footer from './Footer';


function ContactUs(){
    return (
        <>
        <section id="contactus_main-section">
            <Container fluid className="contactus_main-container">
                <div>
                    <h1>Let&#8217;s turn your business idea into reality</h1>
                </div>
                <ListGroup className='contactus-list-group'>
                    <ListGroup.Item><img src="message.png" alt="message"/><span>contact@huraininfotech.com</span></ListGroup.Item>
                    <ListGroup.Item><img src="phone.png" alt="phone"/><span>+91 9773697493 / +91 9022541959 / +91 8097697374</span></ListGroup.Item>
                    {/* <ListGroup.Item ><img src="website.png" alt="website"/><span>www.huraininfotech.com</span></ListGroup.Item> */}
                    <ListGroup.Item ><img src="home.png" alt="home"/><span>C-5, Navjeevan CHS, Mumbai 400070, India</span></ListGroup.Item>
                </ListGroup>
            </Container>
            </section>
            <Footer/>

        </>
    )
}

export default ContactUs