import React from 'react'
import './Home.css'
import { Container } from 'react-bootstrap'

function Home(){
    return (
        <>
        <section id="home_main-section">
      <Container fluid className="home_main-container">
        <section id="home_second-section">
        <Container fluid className="home_second-container">
           <p id="text" className='main_text'>Build Your Custom Software Or Digitalize Your Business</p>
           <p className='second_text'>Web Design and Development Services tailored to your business vision. Captivate your visitors and let your choice be heard.</p>
            </Container>
            </section>
      </Container>
      </section>

        </>
    )
}
export default Home