import React from 'react'
import './About.css'
import { Container } from 'react-bootstrap'

function About(){
    return (
        <>
        <section id="about_main-section">
       <Container fluid className='about_main-container'> 
       <section id="about_second-section">
       <Container className='about_second-container'>
     <img src="aboutus.png" className='aboutimage' alt="aboutimage"/>
     </Container>
     </section>
     <section id="about_third-section">
       <Container className='about_third-container'>
        <h2>About Us</h2>
          <div className='about_text'>
        <p>Hurain infotech LLP is a cutting-edge software development company that provides custom software solutions 
          to businesses of all sizes. With a team of experience software developers and a passion for technology, we deliver high-quality software that help businesses
          streamline their operations and achieve their goals.
            </p>
            <p>We take pride in what we do and specialise in providing software development services across a wide range of domains.</p>
            </div>
       </Container>
       </section>
       </Container>
       </section>
        </>
        )
    }

    export default About