import React from "react";
import "./Technologies.css";
import { Container, Row, Col } from "react-bootstrap";
import { FaNodeJs, FaReact, FaJava, FaVuejs } from "react-icons/fa";
import { SiAngularjs, SiSpringboot } from "react-icons/si";

function Technologies() {
  return (
    <>
      <section id="tech_main-section">
        <Container fluid className="tech_main-container">
          <Container
            fluid
            className="tech_second-container"
          >
            <h1>Technologies and Skills</h1>
            <p>
              We use cutting edge technologies to make custom changes to suits
              the client requirement
            </p>
          </Container>
          <Container fluid className="tech_third-container">
            <Row md={3} xs={2} lg={3}>
              <Col className="tech-col">
                <span className="tech_icon">
                  <FaNodeJs fontSize={70} />
                  <span className="tech_icon-text">Node JS</span>
                </span>
              </Col>
              <Col className="tech-col">
                <span className="tech_icon">
                  <FaReact fontSize={70} />
                  <span className="tech_icon-text">React JS</span>
                </span>
              </Col>
              <Col className="tech-col">
                <span className="tech_icon">
                  <SiAngularjs fontSize={70} />
                  <span className="tech_icon-text">Angular JS</span>
                </span>
              </Col>
              <Col className="tech-col">
                <span className="tech_icon">
                  <FaJava fontSize={70} />
                  <span className="tech_icon-text">Java</span>
                </span>
              </Col>
              <Col className="tech-col">
                <span className="tech_icon">
                  <SiSpringboot fontSize={70} />
                  <span className="tech_icon-text">Spring Boot</span>
                </span>
              </Col>
              <Col className="tech-col">
                <span className="tech_icon">
                  <FaVuejs fontSize={70} />
                  <span className="tech_icon-text">Vue JS</span>
                </span>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
}

export default Technologies;
