import React from 'react';
import './Services.css';
import {Container,Row,Col} from 'react-bootstrap';
import Technologies from './Technologies';
import ServicesPage3 from './ServicesPage3';
import ServicesClient from './ServicesClient';
// import ClientReview from './ClientReview';
function Services(){
    return (
        <>
        <section id="services_main-section">
     <Container fluid className='services_main-container'>
        <section id="services_second-section">
        <Container className='services_second-container'>
            <h5>What We Do</h5>
        <h2>Our About Services</h2>
        <p>Our services include custom software development, software integration,
        software testing and software maintenance.</p>
        </Container>
        </section>
        <section id="services_third-section">
        <Container className='services_third-container'>
        <Row xs={2} lg={4}>
            <Col className='icon_col'><div className="services_image-container"><img src="pagelines.png" className="servicesimage" alt="pageslines"/></div><span className='icon-text'>UI/UX Design</span></Col>
            <Col className='icon_col'><div className="services_image-container"><img src="Mobile.png" className="servicesimage" alt="mobile"/></div><span className='icon-text'>Mobile App Development</span></Col>
            <Col className='icon_col'><div className="services_image-container"><img src="pagelines.png" className="servicesimage" alt="pageslines"/></div><span className='icon-text'>Web Development</span></Col>
            <Col className='icon_col'><div className="services_image-container"><img src="cloud.png" className="servicesimage" alt="cloud"/></div><span className='icon-text'>Cloud Services</span></Col>
            </Row>
            </Container>
            </section>
     </Container>
     </section>
     <Technologies/>
    <ServicesPage3/>
    <ServicesClient/>
    {/* <ClientReview/> */}
        </>
    )
}

export default Services